import React from 'react';
import Select from 'react-select';
import { Field, ErrorMessage, useField } from 'formik';

type Props = {
  options: {
    value: string;
    label: string;
  }[];
  isMulti?: boolean;
  placeholder: string;
  field: typeof Field;
};

const CustomSelect = ({ options, isMulti, placeholder, field: propsField }: Props) => {
  const [field, , helpers] = useField(propsField);

  return (
    <Select
      options={options}
      name={field.name}
      inputId={field.name}
      value={
        options && isMulti
          ? options.filter(option => field.value.indexOf(option.value) >= 0)
          : options.find(option => option.value === field.value)
      }
      onChange={option => helpers.setValue(isMulti ? option.map(item => item.value) : option.value)}
      onBlur={field.onBlur}
      isMulti={isMulti}
      closeMenuOnSelect={false}
      placeholder={placeholder}
      styles={{
        placeholder: provided => ({ ...provided, whiteSpace: 'nowrap' }),
      }}
    />
  );
};

type FieldProps = {
  label: string;
  width: string;
  name: string;
  options: {
    value: string;
    label: string;
  }[];
  isMulti?: boolean;
  placeholder: string;
};

const SelectField = ({ label, width, name, options, placeholder, isMulti }: FieldProps) => (
  <div className={`input-group ${width}`}>
    <label htmlFor={name} className="form-label">
      {label}
    </label>
    <Field
      name={name}
      id={name}
      component={CustomSelect}
      options={options}
      className="form-input"
      placeholder={placeholder}
      isMulti={isMulti}
    />
    <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>} />{' '}
  </div>
);

export default SelectField;
