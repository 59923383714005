import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field, FormikErrors } from 'formik';
import * as Yup from 'yup';
import scrollToElement from 'scroll-to-element';

import TextInputGroup from './inputs/textInput';
import MessageInput from './inputs/message';
import SelectField from './inputs/select';
import { ContrastActionButton } from '../../buttons/buttons';
import Modal from '../../modal';

type FormValues = Record<string, string | string[]>;

export const FormikForm = ({ onSubmit }: { onSubmit: (values: FormValues) => void }) => {
  const initialValues: FormValues = {
    name: '',
    days: [],
    time: [],
    skillLevel: '',
    email: '',
    phone: '',
    message: '',
    'form-name': 'contact-form',
  };

  const schema = {
    name: Yup.string().required('Required'),
    email: Yup.string()
      .email('Must be a valid email address')
      .when('phone', {
        is: (phone: string) => !phone,
        then: Yup.string().required('Please provide at least one contact method'),
      }),
    phone: Yup.string()
      .matches(/^(?:00|\+)?[\d .-]*$/, 'Must be a valid phone number')
      .max(18, 'Must be a valid phone number')
      .min(9, 'Must be a valid phone number'),
    days: Yup.array().min(1, 'Please select at least one day').of(Yup.string()),
    time: Yup.array().min(1, 'Please select at least one time').of(Yup.string()),
    skillLevel: Yup.string(),
    message: Yup.string().required('Required').max(500, 'Message must be less than 500 characters'),
  };

  const focusError = (errors: FormikErrors<typeof schema>) => {
    const keys = Object.keys(errors);
    const element = document.querySelector(`[name="${keys[0]}"]`);
    if (element) scrollToElement(element, { offset: -40, ease: 'linear', duration: 500 });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(schema)}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        onSubmit(values);
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ isValidating, isSubmitting, errors }) => (
        <Form
          style={{ maxWidth: 800 }}
          className="form"
          data-netlify
          name="contact-form"
          data-netlify-honeypot="bot-field"
        >
          {isSubmitting && !isValidating ? focusError(errors) : null}
          <Field type="hidden" name="bot-field" />
          <Field type="hidden" name="form-name" />
          <TextInputGroup name="name" label="Name" width="w-full" />
          <TextInputGroup name="email" label="Email" width="md:w-1/2" />
          <TextInputGroup name="phone" label="Phone" width="md:w-1/2" />
          <SelectField
            label="Preferred Lesson Day(s)"
            name="days"
            placeholder="Select your preferred lesson day(s)"
            width="w-full"
            isMulti
            options={[
              {
                value: 'Monday',
                label: 'Monday',
              },
              {
                value: 'Tuesday',
                label: 'Tuesday',
              },
              {
                value: 'Wednesday',
                label: 'Wednesday',
              },
              {
                value: 'Thursday',
                label: 'Thursday',
              },
              {
                value: 'Friday',
                label: 'Friday',
              },
              {
                value: 'Saturday',
                label: 'Saturday',
              },
              {
                value: 'Sunday',
                label: 'Sunday',
              },
            ]}
          />
          <SelectField
            label="Preferred Lesson Time(s)"
            name="time"
            placeholder="Select your preferred lesson time(s)"
            width="md:w-1/2"
            isMulti
            options={[
              {
                value: 'morning',
                label: 'Morning',
              },
              {
                value: 'afternoon',
                label: 'Afternoon',
              },
              {
                value: 'evening',
                label: 'Evening',
              },
            ]}
          />
          <SelectField
            label="Skill Level"
            name="skillLevel"
            placeholder="Select your skill level"
            width="md:w-1/2"
            options={[
              {
                value: 'beginner',
                label: 'Beginner',
              },
              {
                value: 'intermediate',
                label: 'Intermediate',
              },
              {
                value: 'advanced',
                label: 'Advanced',
              },
            ]}
          />
          <MessageInput />
          <button
            type="submit"
            className="w-full text-center bg-contrast hover:bg-contrast-dark text-white py-4 px-4 rounded mt-2"
          >
            Submit Booking Request
          </button>
        </Form>
      )}
    </Formik>
  );
};

const ContactForm = () => {
  const [notificationMessage, setNotificationMessage] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // TODO set

  const handleSubmit = async (values: FormValues) => {
    setShowModal(true);
    setLoading(true);
    const encodedValues = Object.keys(values)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(values[key].toString())}`)
      .join('&');
    try {
      await axios.post('/', encodedValues, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      setLoading(false);
      setNotificationMessage('Your request was submitted successfully. I hope to respond within 48 hours.');
    } catch (err) {
      setLoading(false);
      setNotificationMessage('Oops, something went wrong. Your booking request was not submitted.');
    }
  };

  return (
    <>
      <FormikForm onSubmit={handleSubmit} />
      {showModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          ariaLabel="Contact form submission status"
          className="py-8 px-5 flex-center flex-col"
        >
          <p style={{ maxWidth: 350 }} className="text-center px-2 mb-4 text-primary-dark">
            {loading ? (
              <span className="font-semibold italic">Submitting booking request . . .</span>
            ) : (
              notificationMessage
            )}
          </p>
          <ContrastActionButton
            onClick={() => {
              setShowModal(false);
              setNotificationMessage(null);
            }}
            label="Dismiss"
          />
        </Modal>
      )}
    </>
  );
};

export default ContactForm;
