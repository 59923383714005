import * as React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import ContactForm from '../components/page-components/forms/contactForm/main';
import Divider from '../components/sections/sectionDivider';
import HeadingSection from '../components/sections/headingSection';
import PageIntro from '../components/sections/pageIntro';

const Contact = () => (
  <Layout>
    <SEO title="Book Now" />
    <HeadingSection heading="Book a Lesson" subheading="Ready to book a guitar lesson? Get in touch!" />
    <Divider />
    <PageIntro
      text="Use the form below to contact me about guitar lessons. Sessions are subject to availability, and are allocated by preference where possible. I look forward to hearing from you!"
      subtext="Please note that until you receive a confirmation from me, the submission of this form is only a request."
    />
    <Divider />
    <div className="bg-primary-lightest page-section-padding">
      <ContactForm />
    </div>
  </Layout>
);

export default Contact;
