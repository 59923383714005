import React from 'react';
import { Field, ErrorMessage } from 'formik';

const MessageInput = () => (
  <div className="input-group w-full">
    <label htmlFor="message" className="form-label">
      Message
    </label>
    <Field
      id="message"
      style={{ height: 150 }}
      name="message"
      as="textarea"
      className="resize-none rounded w-full py-2 px-3"
      placeholder="Tell me about your guitar experience and goals!"
    />
    <ErrorMessage name="message" render={msg => <p className="error-message">{msg}</p>} />
  </div>
);

export default MessageInput;
