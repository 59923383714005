import React from 'react';

const HeadingSection = ({ heading, subheading }: { heading: string; subheading?: string }) => (
  <div className="page-section-padding text-center text-white bg-primary flex flex-col">
    <h1 className="main-heading uppercase">{heading}</h1>
    {subheading && <h2>{subheading}</h2>}
  </div>
);

export default HeadingSection;
