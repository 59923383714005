import React from 'react';
import { Field, ErrorMessage } from 'formik';

const TextInputGroup = ({ label, name, width }: { label: string; name: string; width: string }) => (
  <div className={`input-group ${width}`}>
    <label htmlFor={name} className="form-label">
      {label}
    </label>
    <Field name={name} id={name} type="text" className="form-input" placeholder={`Enter your ${label.toLowerCase()}`} />
    <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>} />
  </div>
);

export default TextInputGroup;
