import React from 'react';

import { FiExternalLink } from 'react-icons/fi';

const PageIntro = ({ text, subtext, link, to }: { text: string; subtext: string; link: string; to: string }) => (
  <div className="py-5 md:py-16 px-4 md:px-10 text-center text-primary bg-primary-lighter">
    <h3 className="text-lg mb-2 md:mb-5">{text}</h3>
    {subtext && <span>{subtext}</span>}
    {link && (
      <a className="underline" href={to}>
        {link}
        <FiExternalLink className="w-4 inline ml-1 align-top" />
      </a>
    )}
  </div>
);

export default PageIntro;
